<template>
  <div>
    <div>
      <div>
        <div class="container pt-5 pb-5">
          <div>
            <h4
              style="
                font-weight: 600;
                font-size: 20px;
                color: rgb(47 47 47 / 70%);
                margin-bottom: 30px;
              "
            >
              Personalize o seu imóvel na planta
            </h4>

            <div class="checkout">
              <div class="listagem-produtos">
                <div data-anima="right">
                  <img
                    v-if="enterprise.image"
                    :src="enterprise.image"
                    class="img-fluid"
                    title="Logo"
                    style="max-height: 30px; margin-bottom: 30px"
                  />

                  <p class="page-txt-view-chamado">KIT PERSONALIZAÇÃO</p>
                  <h3
                    class="page-title-view-chamado-2 font-weight-medium mb-1"
                    style="color: var(--color); font-size: 18px"
                  >
                    UNIDADE {{ unity.number }}
                  </h3>
                </div>
                <div v-show="!loading">
                  <div v-if="personalizations_type.length">
                    <div
                      class="lista-item"
                      data-anima="bottom"
                      v-for="(el, index) in personalizations_type"
                      :key="el.id"
                    >
                      <div class="primeiro-item">
                        <img :src="el.custom_item.image3 ?? el.personalization_type.image" alt="" />
                        <p>{{ el.personalization_type.name }}</p>
                      </div>
                      <div class="segundo-item">
                        <img :src="el.custom_item.image" alt="" />
                        <p>{{ el.custom_item.name }}</p>
                        <span
                          >R$
                          {{
                            formatMoney(
                              el.custom_item ? el.custom_item.price : 0
                            )
                          }}</span
                        >
                      </div>
                      <div class="inputs-item">
                        <div
                          class="form-group mb-0"
                          v-if="el.personalization_type"
                        >
                          <label class="d-none">Alterar</label>
                          <b-form-select
                            v-model="el.personalization_type.id"
                            :disabled="loading"
                          >
                            <!-- :options="personalization_type_options" -->
                            <option :value="el.personalization_type.id">
                              {{ el.personalization_type.name }}
                            </option>
                          </b-form-select>
                        </div>
                        <div v-else>
                          {{ el.personalization_type.name }}
                        </div>
                        <div
                          class="form-group mb-0"
                          v-if="el.personalization_type_itens.length"
                        >
                          <label class="d-none">Alterar </label>
                          <b-form-select
                            @change="
                              selectCustom(
                                $event,
                                el.personalization_type_itens,
                                index
                              )
                            "
                            v-model="custom_selected[index].id"
                            :disabled="loading"
                          >
                            <!-- :options="personalization_type_options" -->
                            <option
                              v-for="type_item in el.personalization_type_itens"
                              :value="type_item.personalization_type_item.id"
                              :key="type_item.personalization_type_item.id"
                            >
                              {{ type_item.personalization_type_item.name }}
                            </option>
                          </b-form-select>
                        </div>
                        <div v-else>
                          {{ el.custom_item ? el.custom_item.name : "" }}
                        </div>
                      </div>
                    </div>

                    <div
                      class="lista-item extra-itens"
                      v-for="(item, index) in order.kits"
                      :key="item.id"
                    >
                      <div class="primeiro-item" v-if="item">
                        <img
                          :src="item.image || item.gallery.image"
                          :alt="`img-${item.name}`"
                        />
                        <p>{{ item.name }}</p>
                      </div>
                      <div class="segundo-item" v-if="item">
                        <div
                          v-for="(group, key) in item.products"
                          :key="key"
                          class="mt-3"
                        >
                          <h3
                            style="
                              color: var(--color);
                              font-size: 15px;
                              font-weight: 600;
                            "
                          >
                            {{ key }}
                          </h3>
                          <p>
                            ({{
                              group
                                .map((elem) => {
                                  return (
                                    elem.product.quantity +
                                    " " +
                                    elem.product.name
                                  );
                                })
                                .join(" | ")
                            }})
                          </p>
                        </div>
                        <span>R$ {{ formatMoney(item ? item.price : 0) }}</span>
                      </div>
                      <div class="inputs-item" v-if="item && !loading">
                        <div
                          class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto order-4 pad-content-list-cta"
                        >
                          <a
                            class="btn btn-dark rounded-circle btn-circle font-16 card-body-list-cta"
                            href="javascript:void(0);"
                            @click="removeKit(index)"
                            ><i class="far fa-trash-alt text-white"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="lista-item"
                  style="display: flex"
                  data-anima="top"
                  v-if="loading"
                >
                  <div
                    class="col-12 cancela-col-padding"
                    style="text-align: center"
                  >
                    <div class="loading">
                      <b-spinner label="Loading..."></b-spinner>
                    </div>
                  </div>
                </div>
              </div>

              <div class="resumo">
                <h5>RESUMO</h5>
                <div class="lista-resumo" v-show="!loading">
                  <div
                    class="item-resumo"
                    data-anima="bottom"
                    v-for="el in personalizations_type"
                    :key="el.id"
                  >
                    <div>
                      <p>
                        {{ el.personalization_type.name }} |
                        {{ el.custom_item.name }}
                      </p>
                      <span
                        >R$
                        {{
                          formatMoney(el.custom_item ? el.custom_item.price : 0)
                        }}</span
                      >
                    </div>
                  </div>

                  <div v-if="order.kits.length" class="mt-4">
                    <div
                      class="item-resumo"
                      v-for="(item, index) in order.kits"
                      :key="item.id"
                    >
                      <div v-if="item">
                        <p>{{ item.name }}</p>
                        <span>R$ {{ formatMoney(item ? item.price : 0) }}</span>
                      </div>
                      <a
                        v-if="!loading"
                        href="#"
                        class="remove-item"
                        @click.prevent="removeKit(index)"
                      >
                        X
                        <span
                          >Retirar do<br />
                          pedido</span
                        >
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 cancela-col-padding"
                  style="text-align: center"
                  v-if="loading"
                >
                  <div class="loading">
                    <b-spinner label="Loading..."></b-spinner>
                  </div>
                </div>
                <h6 class="total-resumo" data-anima="top" v-show="!loading">
                  R$ {{ formatMoney(total) }}
                </h6>
                <h6 class="total-resumo" data-anima="top" v-if="loading">
                  R$ ----
                </h6>
                <div data-anima="top">
                  <div class="form-group mb-3">
                    <div class="mt-2 mb-2" v-if="user['company_id']==12">
                        <p> 
                          Condições de Pagamento da Personalização</br></br>
                          O valor total da personalização podera ser pago das seguintes maneiras:
                        </br></br>
                        • À vista com desconto de 5%.</br></br>•Valor total Parcelado em 6 vezes sem juros.</br></br>
                          
                        • Valor total Parcelado em 12 vezes com correção do INCC a partir da primeira parcela.
                        </p>
                    </div>
                     <textarea v-if="user['company_id'] !=12"
                      class="form-control"
                      rows="6"
                      style="resize: none"
                      placeholder="Preencha a forma de pagamento: no boleto ou em até 6x com parcelas reajustadas pelo incc"
                      v-model="order.observation"
                      :disabled="loading"
                    ></textarea> 
                    <textarea  v-if="user['company_id'] ==12"
                      class="form-control"
                      rows="6"
                      style="resize: none"
                      placeholder="Preencha a forma de pagamento"
                      v-model="order.observation"
                      :disabled="loading"
                    ></textarea> 
                  </div>
                  <div class="footer-form">
                    <b-form-checkbox
                      required
                      class="form-lp form-checkout"
                      v-model="order.terms"
                      :disabled="loading"
                    >
                      Ao continuar, você está de acordo com os

                      <a
                        href="https://r4marketing.com.br/termos-de-uso"
                        target="_blank"
                        style="color: var(--color)"
                        >Termos e Uso </a
                      >e ciente da
                      <a
                        href="https://www.cityinc.com.br/politica-de-privacidade/"
                        target="_blank"
                        style="color: var(--color)"
                        >Política de Privacidade.</a
                      >
                    </b-form-checkbox>
                    <button
                      type="submit"
                      :disabled="!order.terms || loading || order.observation =='' || order.observation ==null"
                      class="btn btn-info font-14 font-b-5"
                      style="
                        background-color: var(--color);
                        margin-top: 30px;
                        height: 55px;
                        width: 100%;
                        font-weight: 600;
                      "
                      @click="addOrder"
                    >
                      FINALIZAR PERSONALIZAÇÃO
                    </button>
                    <BaseClientKitConditions :new_style="true" class="mt-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderService from "@/services/resources/OrderService";
import OrderServiceCancel from "@/services/resources/OrderServiceCancel";
import KitConfigService from "@/services/resources/KitConfigService";
import Money from "@/mixins/money";
import UserService from "@/services/resources/UserService";
import TriggerService from "@/services/resources/TriggerService";
const serviceUser = UserService.build();
const serviceKit = KitConfigService.build();
const service = OrderService.build();
const serviceTrigger = TriggerService.build();
const serviceCancel = OrderServiceCancel.build();

export default {
  name: "Checkoutv2",
  mixins: [Money],

  data() {
    return {
      loading: false,
      order: {
        itens: [],
        kits: [],
        price: 0,
        terms: false,
      },
      enterprise: {
        image: null,
      },
      unity: {
        number: null,
      },
      custom_selected: [],
      personalizations_type: [],
    };
  },
  computed: {
    total() {
      let sum = 0;

      if (!this.order.kits) {
        return 0;
      }

      for (let i = 0; i < this.order.kits.length; i++) {
        sum += this.order.kits[i].price ? this.order.kits[i].price : 0;
      }

      sum += this.personalizations_type.reduce(
        (total, item) => total + item.custom_item.price,
        0
      );

      this.$emit("updateTotal", sum);

      return sum;
    },
    user() {
      return this.$store.getters.getProfile;
    },
  },

  methods: {
    selectCustom(custom_id, options, index) {
      if (options.length) {
        const selected = options.find(
          (op) => op.personalization_type_item.id == custom_id
        );

        if (selected) {
          this.personalizations_type[index].custom_item =
            selected.personalization_type_item;
        }
      }
    },

    selectUser() {
      let data = {
        id: this.order.user_id,
      };

      serviceUser.read(data).then((resp) => {
        resp.enterprises.forEach((element) => {
          if (element.enterprise_id == this.order.enterprise_id) {
            this.enterprise = element.enterprise;
          }
        });
        resp.unities.forEach((element) => {
          if (element.unity_id == this.order.unity_id) {
            this.unity = element.unity;
          }
        });
      });
    },
    addOrder() {
      if (!this.order.terms) {
        this.$bvToast.toast(
          "Para continuar, primeiro aceite os termos de uso e privacidade",
          {
            title: "Atenção",
            autoHideDelay: 5000,
            variant: "info",
          }
        );
        return false;
      }

      this.loading = true;

      let order = Object.assign({}, this.order);
      order.price = this.total;
      order.itens = [];
      this.personalizations_type.forEach((element) => {
        order.itens.push(
          element.personalization_type.id,
          element.custom_item.id
        );
      });

      let order2 = this.order
      order2.price = this.total
      order.order_completa = order2

      service
        .create(order)
        .then(() => {
          this.$router.push("/kit/obrigado/v2");
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          console.log(err.response.data);
          const msgErro =
            err.response.data || "Ocorreu um erro realizar o seu pedido,";

          const retornoErro = msgErro.replace(
            /\\u([\d\w]{4})/gi,
            function (match, grp) {
              return String.fromCharCode(parseInt(grp, 16));
            }
          );

          this.$bvToast.toast(retornoErro, {
            title: "Atenção",
            autoHideDelay: 5000,
            variant: "danger",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeKit(index) {
      const confirm_remove = confirm(
        `Deseja remover o ${this.order.kits[index].name}`
      );
      if (confirm_remove) {
        let kit = this.order.kits[index];
        this.$delete(this.order.kits, index);
        if (kit.type == "MAIN") {
          for (let i = 0; i < this.order.kits.length; i++) {
            if (this.order.kits[i].type == "SECONDARY") {
              this.$delete(this.order.kits, i);
            }
          }
        }
      }
    },
    configItens() {
      if (!this.order.itens) {
        return;
      }

      this.loading = true;
      this.order.itens.forEach((element) => {
        // - antigo stone/ custom selecionado selecionado v-model /
        this.custom_selected.push(element.custom_item);

        //- itens que foram selecionados na etapa anterior
        this.personalizations_type.push(element);
      });

      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    window.scrollTo({ top: 0, behavior: "smooth" });

    let order = JSON.parse(localStorage.order);
    this.order = {
      user_id: this.user.id,
      user: this.user,
      itens: order.itens,
      unity_id: order.unity_id,
      enterprise_id: order.enterprise_id,
      tipology_id: order.tipology_id,
      kits: order.kits,
      terms: false,
    };
    this.unity = order.unity;
    this.configItens();
    this.selectUser();

    const el = document.querySelector(".row.px-2");
    if (el) el.classList.remove("row", "px-2");
  },
};
</script>

<style >
body {
  background: #fff !important;
}
</style>
<style scoped>
.container {
  padding-left: 10px;
  padding-right: 10px;
}
.checkout {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
}
.checkout .listagem-produtos {
  overflow-y: scroll;
  max-height: 140vh;
  margin-top: 40px;
  padding-right: 10px;
  padding-bottom: 30px;
}
::-webkit-scrollbar-thumb {
  background: #ececec;
  border-radius: 5px;
  height: 50px;
}
::-webkit-scrollbar {
  width: 7px;
}
.checkout .lista-item {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 2fr 3fr;
  gap: 30px;
  align-items: center;
  background: rgba(244, 244, 246, 0.5);
  padding: 30px;
  border-radius: 22px;
}
.checkout .lista-item.extra-itens {
  align-items: flex-start;
  grid-template-columns: 1fr 4fr 1fr;
}

.checkout .lista-item .primeiro-item img {
  border-radius: 22px;
  max-width: 180px;
}
.checkout .lista-item .segundo-item img {
  max-width: 85px;
  height: 85px;
  border-radius: 14px;
  margin-bottom: 15px;
}

.checkout .lista-item .primeiro-item p {
  margin: 0;
  margin-top: 15px;
  color: rgba(46, 46, 46, 0.6);
}
.checkout .lista-item .segundo-item p {
  color: #2f2f2f;
  font-weight: 600;
  margin: 0;
}
.checkout .lista-item.extra-itens .segundo-item p {
  font-weight: normal !important;
  font-size: 13px;
  color: rgba(46, 46, 46, 0.6);
  text-transform: uppercase;
}
.checkout .lista-item .segundo-item span {
  color: var(--color);
  font-weight: 600;
  font-size: 30px;
}
.checkout .lista-item.extra-itens .segundo-item span {
  margin-top: 10px;
  display: block;
}
.checkout .lista-item .inputs-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}
.checkout .lista-item .inputs-item select {
  height: 55px;
}
.checkout .resumo {
}
.checkout .resumo h5 {
  color: #9e9e9a;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #9e9e9a3d;
  font-weight: 500;
  margin-bottom: 40px;
}
.checkout .resumo .item-resumo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout .resumo .item-resumo + .item-resumo {
  margin-top: 25px;
}
.checkout .resumo .item-resumo p {
  margin: 0;
  color: #9e9e9a;
  font-size: 14px;
}
.checkout .resumo .item-resumo span {
  font-size: 22px;
  font-weight: 600;
  color: rgba(47, 47, 47, 0.8);
}
.checkout .resumo .item-resumo .remove-item {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 22px;
  color: #9e9e9a;
}
.checkout .resumo .item-resumo .remove-item span {
  font-weight: normal;
  font-size: 13px;
  color: #9e9e9a;
}
.checkout .resumo .total-resumo {
  color: var(--color);
  font-weight: 600;
  font-size: 36px;
  margin: 40px 0;
  padding: 20px 0;
  margin-bottom: 0;
  border-top: 1px solid #9e9e9a3d;
}
.formulario .footer-form {
  display: flex;
  align-items: center;
  gap: 20px;
}
.formulario .footer-form button {
  padding: 5px 40px;
  /* height: 50px; */
  border-radius: 10px;
  font-size: 14px;
  height: 60px;
}

.checkout .lista-item .primeiro-item p {
  margin: 0;
  margin-top: 15px;
  color: rgba(46, 46, 46, 0.6);
}
.checkout .lista-item .segundo-item p {
  color: #2f2f2f;
  font-weight: 600;
  margin: 0;
}
.checkout .lista-item.extra-itens .segundo-item p {
  font-weight: normal !important;
  font-size: 13px;
  color: rgba(46, 46, 46, 0.6);
  text-transform: uppercase;
}
.checkout .lista-item .segundo-item span {
  color: var(--color);
  font-weight: 600;
  font-size: 30px;
}
.checkout .lista-item.extra-itens .segundo-item span {
  margin-top: 10px;
  display: block;
}
.checkout .lista-item .inputs-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}
.checkout .lista-item .inputs-item select {
  height: 55px;
}
.checkout .resumo {
}
.checkout .resumo h5 {
  color: #9e9e9a;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #9e9e9a3d;
  font-weight: 500;
  margin-bottom: 40px;
}
.checkout .resumo .item-resumo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout .resumo .item-resumo + .item-resumo {
  margin-top: 25px;
}
.checkout .resumo .item-resumo p {
  margin: 0;
  color: #9e9e9a;
  font-size: 14px;
}
.checkout .resumo .item-resumo span {
  font-size: 22px;
  font-weight: 600;
  color: rgba(47, 47, 47, 0.8);
}
.checkout .resumo .item-resumo .remove-item {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 22px;
  color: #9e9e9a;
}
.checkout .resumo .item-resumo .remove-item span {
  font-weight: normal;
  font-size: 13px;
  color: #9e9e9a;
}
.checkout .resumo .total-resumo {
  color: var(--color);
  font-weight: 600;
  font-size: 36px;
  margin: 40px 0;
  padding: 20px 0;
  margin-bottom: 0;
  border-top: 1px solid #9e9e9a3d;
}
.formulario .footer-form {
  display: flex;
  align-items: center;
  gap: 20px;
}
.formulario .footer-form button {
  padding: 5px 40px;
  /* height: 50px; */
  border-radius: 10px;
  font-size: 14px;
  height: 60px;
}
@media screen and (max-width: 768px) {
  .checkout {
    grid-template-columns: 1fr;
  }
  .checkout .listagem-produtos {
    overflow-y: auto;
    max-height: initial;
    padding: 0;
  }
  .checkout .lista-item {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 20px;
  }
  .checkout .lista-item.extra-itens {
    grid-template-columns: 1fr;
  }
}
</style>